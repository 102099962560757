import Select from "react-select";
import "./Header.css";
import {
	Button,
	Col,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	Image,
} from "react-bootstrap";
import { apiUrl, email, mode, uploadsUrl } from "../globsls";

import Czat from "./components/Czat";
import Powiadomienia from "./components/Powiadomienia";
import { useEffect, useState } from "react";
export default function Header(props) {
	const {
		loggedIn,
		setLoggedIn,
		biogazList,
		selBiogaz,
		setSelBiogaz,
		page,
		setPage,
		reload,
		key2,
		setKey,
	} = props;
	const [notifications, setNofications] = useState(0);
	const [notifications2, setNofications2] = useState(0);
	const [showChat, setShowChat] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);
	useEffect(() => {
		if (selBiogaz) {
			const form = new FormData();
			form.append("action", "getWiadomosci");
			form.append("id", selBiogaz.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					console.log(res);
					setNofications(
						res.filter((a) =>
							a.odczytano ? !a.odczytano.includes(`^${loggedIn.id}#`) : true
						).length
					);
				});
		}
		if (selBiogaz) {
			const form = new FormData();
			form.append("action", "getPowiadomienia");
			form.append("id", selBiogaz.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					console.log(res);
					setNofications2(
						res.filter((a) =>
							a.odczytano ? !a.odczytano.includes(`^${loggedIn.id}#`) : true
						).length
					);
				});
		}
	}, [loggedIn.id, selBiogaz]);
	return (
		<div className="Header">
			<Row className="hrow">
				<Col md="4">
					<div className="leftWing">
						<img
							src={"/logo.png"}
							alt="logo"
							className="logo"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setKey("glowny");
								setPage("panelBiogaz");
							}}
						></img>
						{loggedIn && (
							<div className="buttonsMenu">
								<div
									style={{ color: "black", cursor: "pointer" }}
									onClick={() => window.open("http://ak-store.pl")}
								>
									<div>
										<span class="material-symbols-outlined">shopping_cart</span>
										Przejdź do sklepu
									</div>
								</div>
								<div
									style={{ color: "green", cursor: "pointer" }}
									onClick={() =>
										(window.location = `mailto:serwis@agrikomp.pl?subject=Pytanie o ofertę inwestora ${loggedIn.nazwa} biogazownia ${selBiogaz.nazwa}`)
									}
								>
									<div>
										<span class="material-symbols-outlined">request_quote</span>
										Zapytaj o ofertę
									</div>
								</div>
								<div
									style={{ color: "darkred", cursor: "pointer" }}
									onClick={() =>
										(window.location = `mailto:serwis@agrikomp.pl?subject=Zgłoszenie inwestora ${loggedIn.nazwa} biogazownia ${selBiogaz.nazwa}`)
									}
								>
									<div>
										<span class="material-symbols-outlined">upcoming</span>Dodaj
										zgłoszenie
									</div>
								</div>
							</div>
						)}
					</div>
				</Col>
				<Col>
					{loggedIn && page === "panelBiogaz" && biogazList.length !== 0 ? (
						<Select
							onChange={(value) => {
								setSelBiogaz(value.value);
								reload((a) => !a);
							}}
							className="select"
							defaultValue={{
								value: selBiogaz.id,
								label: selBiogaz.nazwa,
							}}
							styles={{
								control: (base) => ({
									...base,
									backgroundColor: "#efecec", // Background for the control (input container)
								}),
								valueContainer: (base) => ({
									...base,
									backgroundColor: "#efecec", // Background for the value container (text area)
								}),
								dropdownIndicator: (base) => ({
									...base,
									backgroundColor: "#efecec", // Background for the dropdown arrow
								}),
								indicatorsContainer: (base) => ({
									...base,
									backgroundColor: "#efecec", // Background for the entire arrow container
								}),
							}}
							options={biogazList.map((a) => ({ value: a, label: a.nazwa }))}
						></Select>
					) : (
						<h2>
							<center></center>
						</h2>
					)}
				</Col>
				<Col md="4">
					<Row
						style={{ float: "right" }}
						hidden={!loggedIn}
						className="rightWing"
					>
						<Col md="auto">
							<Dropdown show={showChat} onToggle={() => setShowChat((a) => !a)}>
								<DropdownToggle variant="none">
									<div className="circles">
										<span className="material-symbols-outlined">Chat</span>
										{notifications !== 0 &&
											(notifications < 10 ? (
												<span className="chatNotifications">
													<span className="notificationCount">
														{notifications}
													</span>
												</span>
											) : (
												<span className="chatNotifications">
													<span
														className="notificationCount"
														style={{ fontSize: "12px", marginLeft: "1px" }}
													>
														9+
													</span>
												</span>
											))}
									</div>
								</DropdownToggle>
								<Dropdown.Menu>
									<Czat
										loggedIn={loggedIn}
										selBiogaz={selBiogaz}
										setNofications={setNofications}
										notifications={notifications}
										showChat={showChat}
									></Czat>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						<Col md="auto" hidden={loggedIn.typ !== "3" && 0}>
							<Dropdown
								show={showNotifications}
								onToggle={() => setShowNotifications((a) => !a)}
							>
								<DropdownToggle variant="none">
									<div className="circles">
										<span className="material-symbols-outlined">
											notifications
										</span>
										{notifications2 !== 0 &&
											(notifications2 < 10 ? (
												<span className="chatNotifications">
													<span className="notificationCount">
														{notifications2}
													</span>
												</span>
											) : (
												<span className="chatNotifications">
													<span
														className="notificationCount"
														style={{ fontSize: "12px", marginLeft: "1px" }}
													>
														9+
													</span>
												</span>
											))}
									</div>
								</DropdownToggle>
								<Dropdown.Menu>
									<Powiadomienia
										loggedIn={loggedIn}
										selBiogaz={selBiogaz}
										setNofications2={setNofications2}
										notifications2={notifications2}
										showNotifications={showNotifications}
									></Powiadomienia>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						<Col md="auto" hidden={loggedIn.typ === "3"}>
							<Dropdown>
								<DropdownToggle variant="none">
									<div className="circles">
										<span className="material-symbols-outlined">menu_book</span>
									</div>
								</DropdownToggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => setPage("panelBiogaz")}>
										Panel biogazowni
									</Dropdown.Item>
									{loggedIn.typ === "1" ? (
										<Dropdown.Item onClick={() => setPage("panelAdmin")}>
											Panel administratora
										</Dropdown.Item>
									) : (
										<Dropdown.Item onClick={() => setPage("panelSerwis")}>
											Panel serwisu
										</Dropdown.Item>
									)}
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						<Col md="auto">
							<Dropdown>
								<DropdownToggle variant="none">
									{loggedIn.obraz !== null ? (
										<Image
											roundedCircle
											src={uploadsUrl + loggedIn.obraz}
											className="circles profil"
										></Image>
									) : (
										<span
											className="material-symbols-outlined circles profil"
											style={{ fontSize: "76px", padding: "4px" }}
										>
											person
										</span>
									)}
								</DropdownToggle>
								<Dropdown.Menu>
									<DropdownItem
										variant="none"
										onClick={() => setPage("opcjeKonta")}
									>
										<div>
											<span>Opcje konta</span>{" "}
										</div>
									</DropdownItem>
									<DropdownItem
										onClick={() => {
											document.cookie =
												"loggedIn=; expires=Thu, 18 Dec 2013 12:00:00 UTC";
											setLoggedIn(false);
										}}
									>
										<div>
											<span>Wyloguj</span>{" "}
										</div>
									</DropdownItem>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}
