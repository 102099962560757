import "./Kogeneracji.css";
import Czesci, { JkForm } from "../../components/Czesci";
import { Col, Row, Table } from "react-bootstrap";
import Zalecenia from "../../components/Zalecenia";
import Przeglady, { PrzegladyForm } from "../../components/Przeglady";
import Instrukcje, { InstrukcjeForm } from "../../components/Instrukcje";
import { ZaleceniaForm } from "../../components/Zalecenia";
import KogeneracjiGlowny from "./KogeneracjiGlowny";
import KogeneracjiKontakt from "./KogeneracjiKontakt";

export default function Kogeneracji(props) {
	const { selBiogaz, loggedIn, reload, refresh, page } = props;
	switch (page) {
		case "pzp":
			return (
				<div>
					<h2>Plan i zakres przeglądów</h2>
					<Row>
						<Col>
							<Przeglady
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								przegmode={["k", "a"]}
								uprawnienia={loggedIn.typ === "23" || loggedIn.typ === "1"}
							></Przeglady>
						</Col>
						{(loggedIn.typ === "23" || loggedIn.typ === "1") && (
							<Col md="2">
								<PrzegladyForm
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									przegmode={"k"}
								></PrzegladyForm>
							</Col>
						)}
					</Row>
				</div>
			);
		case "zam":
			return (
				<>
					<Row>
						<Col>
							<h2>Zamówienia</h2>
							<Czesci
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								typ="kogeneracji"
							></Czesci>
						</Col>
						{(loggedIn.typ === "23" || loggedIn.typ === "1") && (
							<Col md="2">
								<JkForm
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									typ="kogeneracji"
								></JkForm>
							</Col>
						)}
					</Row>
				</>
			);
		case "kon":
			return (
				<div>
					<h2>Kontakt</h2>
					<Row>
						<Col>
							<KogeneracjiKontakt
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								zalmode={"b"}
								uprawnienia={loggedIn.typ === "21" || loggedIn.typ === "1"}
							></KogeneracjiKontakt>
						</Col>
					</Row>
				</div>
			);
		case "hnp":
			return (
				<div>
					<h2>Historia napraw i przeglądów</h2>
					<Row>
						<Col>
							<Przeglady
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								przegmode={["k", "h"]}
								uprawnienia={loggedIn.typ === "23" || loggedIn.typ === "1"}
							></Przeglady>
						</Col>
						{/* <Col md="2">
							<PrzegladyForm
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								przegmode={"k"}
								wykonano
							></PrzegladyForm>
						</Col> */}
					</Row>
				</div>
			);
		case "zal":
			return (
				<div>
					<h2>Zalecenia</h2>
					<Row>
						<Col>
							<Zalecenia
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								zalmode={"k"}
								uprawnienia={loggedIn.typ === "23" || loggedIn.typ === "1"}
							></Zalecenia>
						</Col>
						{loggedIn.typ === "23" || loggedIn.typ === "1" ? (
							<Col md={2}>
								<ZaleceniaForm
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									zalmode={"k"}
								></ZaleceniaForm>
							</Col>
						) : (
							<Col>
								<h2>Dodatkowe usługi</h2>
								<ul>
									<li>Badania boroskopowe,</li>
									<li>Ekspertyzy silnika,</li>
									<li>Przegląd nieplanowy,</li>
									<li>Regulacje silnika.</li>
								</ul>
							</Col>
						)}
					</Row>
				</div>
			);
		case "ins":
			return (
				<div>
					<h2>Instrukcje</h2>
					<Row>
						<Col>
							<Instrukcje
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								insmode={"k"}
							></Instrukcje>
						</Col>
						{(loggedIn.typ === "23" || loggedIn.typ === "1") && (
							<Col md="2">
								<InstrukcjeForm
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									insmode={"k"}
									uprawnienia={loggedIn.typ === "23" || loggedIn.typ === "1"}
									selected={["b", selBiogaz.id]}
								></InstrukcjeForm>
							</Col>
						)}
					</Row>
				</div>
			);
		default:
			return (
				<KogeneracjiGlowny
					selBiogaz={selBiogaz}
					loggedIn={loggedIn}
					reload={reload}
					refresh={refresh}
				></KogeneracjiGlowny>
			);
	}
}
