import "./Biologiczny.css";
import Zalecenia, { ZaleceniaForm } from "../../components/Zalecenia";
import { Button, Col, Row, Table } from "react-bootstrap";
import BiologicznyGlowny from "./BiologicznyGlowny";
import { useRef } from "react";
import BiologicznyKontakt from "./BiologicznyKontakt";

export default function Biologiczny(props) {
	const { selBiogaz, loggedIn, reload, refresh, page } = props;
	const targetRef = useRef(null); // useRef to capture the target element

	const handleScroll = () => {
		if (targetRef.current) {
			targetRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};
	switch (page) {
		case "wbmf":
			return (
				<div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<h2 style={{ display: "inline" }}>
							Wyniki badań masy fermentacyjnej
						</h2>
						<div></div>
						<Button
							variant="link"
							style={{
								fontSize: "1rem",
								display: "inline-block",
							}}
							onClick={() => handleScroll()}
						>
							dowiedz się, jak czytać wyniki badań
						</Button>
					</div>

					<Row>
						<Col>
							<Zalecenia
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								zalmode={"f"}
								uprawnienia={loggedIn.typ === "21" || loggedIn.typ === "1"}
							></Zalecenia>
						</Col>
						{(loggedIn.typ === "21" || loggedIn.typ === "1") && (
							<Col md={2}>
								<ZaleceniaForm
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									zalmode={"f"}
								></ZaleceniaForm>
							</Col>
						)}
					</Row>
					<Row
						hidden={loggedIn.typ === "21" || loggedIn.typ === "1"}
						ref={targetRef}
					>
						<Col md={7} style={{ fontSize: "larger", lineHeight: "2.2em" }}>
							<b>pH</b> – pozwala na kontrolowanie odczynu optymalnego dla
							funkcjonowania bakterii <br></br>
							<b>FOS</b> – suma wszystkich lotnych kwasów organicznych – w ich
							skład wchodzi kwas octowy, kwas propionowy, kwas walerianowy i
							kwas masłowy, określa czy karmienie biogazowni jest prawidłowe.
							<br></br>
							<b>TAC</b> – alkaliczna pojemność buforowa. Czynnik ten
							stabilizuje proces fermentacji i zapobiega gwałtownym wahaniom pH.
							FOS/TAC – określa stopień obciążenia procesu fermentacji
							metanowej. Pozwala kontrolować stan fermentacji, zbyt wysoki
							wskaźnik FOS/TAC wskazuje na przekarmienie biogazowni.<br></br>
							<b>Kwas octowy, propionowy, walerianowy i masłowy</b> – lotne
							kwasy organiczne, główne źródło metanu.<br></br>
							<b>SM%</b> – zawartość suchej masy podany w % <br></br>
							<b>oSM</b> – zawartość suchej masy organicznej podany w %{" "}
							<br></br>
							<b>oSM [%SM]</b> – zawartość suchej masy organicznej w % jako %
							suchej masy <br></br>
							<b>Azot amonowy (NH4NO3)</b> – powiązany z TAC, buforuje nadmiar
							kwasów korzystnie wpływając na pracę baterii, jednak w zbyt
							wysokich stężeniach może działać jak inhibitor.<br></br>
							<b>Przew. Elek.</b> – określa poziom zasolenia<br></br>
						</Col>
						<Col md={3} style={{ margin: "auto" }}>
							<h4>Prawidłowe wyniki badań</h4>
							<Table>
								<thead>
									<tr>
										<th>Mierzone parametry</th>
										<th>Prawidłowe wartości</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>pH</td>
										<td>min. 7,2 max 8,00</td>
									</tr>
									<tr>
										<td>FOS</td>
										<td>do 5000 mg/l</td>
									</tr>
									<tr>
										<td>TAC</td>
										<td>min. 10000mg/l</td>
									</tr>
									<tr>
										<td>FOS/TAC</td>
										<td>max. 0,4</td>
									</tr>
									<tr>
										<td>kwas octowy</td>
										<td>max. 3000 mg/l</td>
									</tr>
									<tr>
										<td>kwas propionowy</td>
										<td>max. 800 mg/l</td>
									</tr>
									<tr>
										<td>kwas masłowy</td>
										<td>max. 500 mg/l</td>
									</tr>
									<tr>
										<td>kwas walerianowy</td>
										<td>max. 500 m</td>
									</tr>
									<tr>
										<td>SM</td>
										<td>min. 5,0 max 10,5 %</td>
									</tr>
									<tr>
										<td>oSM</td>
										<td>max. 10,5 %</td>
									</tr>
									<tr>
										<td>OSM %</td>
										<td>min. 60,0 %</td>
									</tr>
									<tr>
										<td>NH4-N</td>
										<td>min. 1500 mg/l max. 3000 mg/l</td>
									</tr>
									<tr>
										<td>przewodność</td>
										<td>min, 10,0 mS/cm max. 40,0 mS/cm</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</div>
			);
		case "pb":
			return (
				<div>
					<h2>Pozostałe badania</h2>
					<Row>
						<Col>
							{" "}
							<Zalecenia
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								zalmode={"p"}
								uprawnienia={loggedIn.typ === "21" || loggedIn.typ === "1"}
							></Zalecenia>
						</Col>

						{(loggedIn.typ === "21" || loggedIn.typ === "1") && (
							<Col md={2}>
								<ZaleceniaForm
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									zalmode={"p"}
								></ZaleceniaForm>
							</Col>
						)}
					</Row>
				</div>
			);
		case "zal":
			return (
				<div>
					<h2>Zalecenia</h2>
					<Row>
						<Col>
							<Zalecenia
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								zalmode={"b"}
								uprawnienia={loggedIn.typ === "21" || loggedIn.typ === "1"}
							></Zalecenia>
						</Col>
						{loggedIn.typ === "21" || loggedIn.typ === "1" ? (
							<Col md={2}>
								<ZaleceniaForm
									selBiogaz={selBiogaz}
									loggedIn={loggedIn}
									reload={reload}
									refresh={refresh}
									zalmode={"b"}
								></ZaleceniaForm>
							</Col>
						) : (
							<Col>
								<h2>Zalecane praparaty</h2>Zdjęcia zalecanych preparatow
							</Col>
						)}
					</Row>
				</div>
			);
		case "kon":
			return (
				<div>
					<h2>Kontakt</h2>
					<Row>
						<Col>
							<BiologicznyKontakt
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								zalmode={"b"}
								uprawnienia={loggedIn.typ === "21" || loggedIn.typ === "1"}
							></BiologicznyKontakt>
						</Col>
					</Row>
				</div>
			);
		default:
			return (
				<BiologicznyGlowny
					selBiogaz={selBiogaz}
					loggedIn={loggedIn}
					reload={reload}
					refresh={refresh}
				></BiologicznyGlowny>
			);
	}
}
