import "./Diagnoza.css";

import { mode, apiUrl } from "../../../globsls";
import { useState, useRef } from "react";
import {
	Col,
	Row,
	Table,
	Button,
	Modal,
	Form,
	FormGroup,
	FormLabel,
	FormCheck,
	FormControl,
	FormSelect,
} from "react-bootstrap";

export default function Diagnoza(props) {
	const { selBiogaz, loggedIn, reload, setSelBiogaz } = props;
	const [show, setShow] = useState(false);
	const status_zwRef = useRef(null);
	const status_fRef = useRef(null);
	const status_dRef = useRef(null);
	const status_zkRef = useRef(null);
	const status_vRef = useRef(null);
	const status_mRef = useRef(null);

	function submitHandler(event) {
		const form = new FormData();
		form.append("action", "updateDiagnoza");
		form.append("user", JSON.stringify(loggedIn));
		form.append("id", selBiogaz.id);
		form.append("status_zw", status_zwRef.current.value);
		form.append("status_f", status_fRef.current.value);
		form.append("status_d", status_dRef.current.value);
		form.append("status_zk", status_zkRef.current.value);
		form.append("status_v", status_vRef.current.value);
		form.append("status_m", status_mRef.current.value);
		form.append("notify", "true");
		form.append("biogazChannel", selBiogaz.id);
		form.append("posiadacz", selBiogaz.posiadacz);
		form.append("channel", "powiadomieniaOsb");
		form.append(
			"subject",
			"Zmiana oceny ogólnego stanu biogazowni w aplikacji AgriService"
		);
		form.append("subject2", "Zmiana oceny ogólnego stanu biogazowni");
		form.append(
			"notification",
			`Zmieniła się ogólna ocenia stanu biogazowni w aplikacji AgriService. Zapoznaj się z nią na stronie <a href="https://agriservice.pl/">https://agriservice.pl/</a>`
		);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then(() =>
				setSelBiogaz((a) => ({
					...a,
					status_zw: status_zwRef.current.value,
					status_f: status_fRef.current.value,
					status_d: status_dRef.current.value,
					status_zk: status_zkRef.current.value,
					status_v: status_vRef.current.value,
					status_m: status_mRef.current.value,
				}))
			)
			.then(() => {
				reload((a) => !a);
				setShow(false);
			});
	}
	return (
		<div className="Diagnoza">
			<h4>Ogólny stan biogazowni</h4>
			<Table bordered>
				<thead>
					<tr className="header">
						<th>Obszar</th>
						<th>Zbiornik wstępny</th>
						<th>Fermentator</th>
						<th>Dofermentator</th>
						<th>Zbiornik końcowy</th>
						<th>Viefrass</th>
						<th>Międzysztacht</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th rowSpan={2}>Diagnoza</th>
						<th>
							<Status stan={selBiogaz.status_zw}></Status>
						</th>
						<th>
							<Status stan={selBiogaz.status_f}></Status>
						</th>
						<th>
							<Status stan={selBiogaz.status_d}></Status>
						</th>
						<th>
							<Status stan={selBiogaz.status_zk}></Status>
						</th>
						<th>
							<Status stan={selBiogaz.status_v}></Status>
						</th>
						<th>
							<Status stan={selBiogaz.status_m}></Status>
						</th>
					</tr>
					<tr className="status">
						<th>
							<Status2 stan={selBiogaz.status_zw}></Status2>
						</th>
						<th>
							<Status2 stan={selBiogaz.status_f}></Status2>
						</th>
						<th>
							<Status2 stan={selBiogaz.status_d}></Status2>
						</th>
						<th>
							<Status2 stan={selBiogaz.status_zk}></Status2>
						</th>
						<th>
							<Status2 stan={selBiogaz.status_v}></Status2>
						</th>
						<th>
							<Status2 stan={selBiogaz.status_m}></Status2>
						</th>
					</tr>
				</tbody>
				{loggedIn.typ !== "3" && selBiogaz && (
					<tfoot>
						<tr>
							<th colSpan={10}>
								<Button onClick={() => setShow(true)}>Edytuj</Button>
							</th>
						</tr>
					</tfoot>
				)}
			</Table>
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Edytuj diagnozę</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={submitHandler}>
						<FormGroup>
							<FormLabel>Zbiornik wstępny</FormLabel>
							<FormSelect
								name="status_zw"
								defaultValue={selBiogaz.status_zw}
								ref={status_zwRef}
							>
								<option value={1}>W porządku</option>
								<option value={2}>Zalecana obserwacja</option>
								<option value={3}>Wymagana ingerencja</option>
							</FormSelect>
						</FormGroup>
						<FormGroup>
							<FormLabel>Fermentator</FormLabel>
							<FormSelect
								name="status_f"
								defaultValue={selBiogaz.status_f}
								ref={status_fRef}
							>
								<option value={1}>W porządku</option>
								<option value={2}>Zalecana obserwacja</option>
								<option value={3}>Wymagana ingerencja</option>
							</FormSelect>
						</FormGroup>
						<FormGroup>
							<FormLabel>Dofermentator</FormLabel>
							<FormSelect
								name="status_d"
								defaultValue={selBiogaz.status_d}
								ref={status_dRef}
							>
								<option value={1}>W porządku</option>
								<option value={2}>Zalecana obserwacja</option>
								<option value={3}>Wymagana ingerencja</option>
							</FormSelect>
						</FormGroup>
						<FormGroup>
							<FormLabel>Zbiornik końcowy</FormLabel>
							<FormSelect
								name="status_zk"
								defaultValue={selBiogaz.status_zk}
								ref={status_zkRef}
							>
								<option value={1}>W porządku</option>
								<option value={2}>Zalecana obserwacja</option>
								<option value={3}>Wymagana ingerencja</option>
							</FormSelect>
						</FormGroup>
						<FormGroup>
							<FormLabel>Viefrass</FormLabel>
							<FormSelect
								name="status_v"
								defaultValue={selBiogaz.status_v}
								ref={status_vRef}
							>
								<option value={1}>W porządku</option>
								<option value={2}>Zalecana obserwacja</option>
								<option value={3}>Wymagana ingerencja</option>
							</FormSelect>
						</FormGroup>
						<FormGroup>
							<FormLabel>Międzysztacht</FormLabel>
							<FormSelect
								name="status_m"
								defaultValue={selBiogaz.status_m}
								ref={status_mRef}
							>
								<option value={1}>W porządku</option>
								<option value={2}>Zalecana obserwacja</option>
								<option value={3}>Wymagana ingerencja</option>
							</FormSelect>
						</FormGroup>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Close
					</Button>
					<Button variant="primary" onClick={() => submitHandler()}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
function Status({ stan }) {
	switch (stan) {
		case "1":
			return (
				<div
					style={{
						display: "flex",
						justifyItems: "center",
						color: "green",
					}}
				>
					<span
						className="material-symbols-outlined"
						style={{
							fontVariationSettings: "'FILL' 1,'wght' 400,'GRAD' 0,'opsz' 48",
							fontSize: "48px",
						}}
					>
						thumb_up
					</span>
				</div>
			);
		case "2":
			return (
				<div
					style={{
						display: "flex",
						justifyItems: "center",
						color: "orange",
					}}
				>
					<span
						className="material-symbols-outlined"
						style={{
							fontVariationSettings: "'FILL' 1,'wght' 400,'GRAD' 0,'opsz' 48",
							fontSize: "48px",
						}}
					>
						front_hand
					</span>
				</div>
			);
		case "3":
			return (
				<div
					style={{
						display: "flex",
						justifyItems: "center",
						color: "red",
					}}
				>
					<span
						className="material-symbols-outlined"
						style={{
							fontVariationSettings: "'FILL' 1,'wght' 400,'GRAD' 0,'opsz' 48",
							fontSize: "48px",
						}}
					>
						thumb_down
					</span>
				</div>
			);
		default:
			return <div>Nieznana</div>;
	}
}
function Status2({ stan }) {
	switch (stan) {
		case "1":
			return <div style={{ color: "green" }}>W porządku</div>;
		case "2":
			return <div style={{ color: "orange" }}>Zalecana obserwacja</div>;
		case "3":
			return <div style={{ color: "red" }}>Wymagana ingerencja</div>;
		default:
			return <div>Status nieznany</div>;
	}
}
