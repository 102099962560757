import "./Instrukcje.css";
import { useEffect, useRef, useState } from "react";
import { apiUrl, mode, uploadsUrl } from "../../globsls";
import ReactPlayer from "react-player";
import {
	Button,
	Col,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	Row,
	Table,
} from "react-bootstrap";

export default function Instrukcje(props) {
	const { selBiogaz, loggedIn, reload, refresh, insmode } = props;
	const [instrukcje, setInstrukcje] = useState([]);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getInstrukcje");
		form.append("id", selBiogaz.id);
		form.append("tryb", insmode);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setInstrukcje(res));
	}, [selBiogaz, insmode, refresh]);
	console.log(insmode);
	return (
		<div className="Instrukcje">
			<Table bordered striped>
				<thead>
					<tr>
						<th>
							Instrukcje do{" "}
							{insmode === "k" ? "jednostek kogeneracyjnych" : "biogazowni"}
						</th>
					</tr>
				</thead>
				<tbody>
					{instrukcje
						.filter((b) => !b.idczesci)
						.map((a, i) => (
							<Instrukcja
								key={i}
								nazwa={a.nazwa}
								url={a.plik ? uploadsUrl + a.plik : a.link}
							></Instrukcja>
						))}
				</tbody>
			</Table>
			<Table bordered striped>
				<thead>
					<tr>
						<th>Instrukcje do części</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							{Object.values(
								Object.groupBy(
									instrukcje.filter((b) => b.idczesci),
									({ idczesci }) => idczesci
								)
							).map((a, i) => (
								<div key={i}>
									<h4>
										{a[0].czesc ||
											a[0].typ.match(/\b(\w)/g).join("") + a[0].specyfikacja}
									</h4>
									<div>
										{a.map((b, i) => (
											<Instrukcja
												key={i}
												nazwa={b.nazwa}
												url={b.plik ? uploadsUrl + b.plik : b.link}
											></Instrukcja>
										))}
									</div>
								</div>
							))}
						</td>
					</tr>
				</tbody>
			</Table>
			{/* <div>Instrukcje opisowe znajdują się pod poniższym linikiem</div>
			<div>
				<a
					href="http://www.linkdoinstrukcjiagrikomp.pl/"
					target="_BLANK"
					rel="noreferrer"
				>
					http://www.linkdoinstrukcjiagrikomp.pl/
				</a>
			</div> */}
		</div>
	);
}
function Instrukcja(props) {
	const [show, setShow] = useState(false);
	function delHandler() {
		if (window.confirm(`Czy na pewno chcesz usunąć insrukcję ${props.nazwa}`)) {
			const form = new FormData();
			form.append("action", "delInstrukcja");
			form.append("id", props.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => props.reload((a) => !a));
		}
	}
	if (
		props.url.toLocaleLowerCase().includes(".mp4") ||
		props.url.toLocaleLowerCase().includes("youtube") ||
		props.url.toLocaleLowerCase().includes("youtu.be") ||
		props.url.toLocaleLowerCase().includes("video") ||
		props.url.toLocaleLowerCase().includes(".mov") ||
		props.url.toLocaleLowerCase().includes(".avi")
	) {
		return (
			<tr>
				<td>
					<div>
						<div style={{ display: "flex", padding: "4px" }}>
							<span
								style={{
									fontWeight: "bold",
									fontSize: "larger",
									marginRight: "4px",
								}}
							>
								Film {props.nazwa}
							</span>
							<Button onClick={() => setShow((a) => !a)} size="sm">
								{show ? "Ukryj film" : "Wyświetl film"}
							</Button>
							{props.uprawnienia && (
								<Button size="sm" variant="danger" onClick={() => delHandler()}>
									Usuń
								</Button>
							)}
						</div>
						{show && (
							<ReactPlayer
								className={"video"}
								controls
								url={props.url}
							></ReactPlayer>
						)}
					</div>
				</td>
			</tr>
		);
	} else {
		return (
			<tr>
				<td>
					<div>
						<a href={props.url} target="_BLANK" rel="noreferrer">
							{props.nazwa}
						</a>{" "}
						{props.uprawnienia && (
							<Button size="sm" variant="danger" onClick={() => delHandler()}>
								Usuń
							</Button>
						)}
					</div>
				</td>
			</tr>
		);
	}
}
export function InstrukcjeForm(props) {
	const { loggedIn, reload, refresh, insmode, selected } = props;
	const [instrukcje, setInstrukcje] = useState([]);
	const [plik, setPlik] = useState(true);
	useEffect(() => {
		const form = new FormData();
		form.append("action", "getInstrukcje");
		form.append("id", selected[1]);
		form.append("tryb", insmode);
		if (selected[0] === "c") {
			form.append("czesc", selected[1]);
		}
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => setInstrukcje(res));
	}, [insmode, selected, refresh]);
	const plikRef = useRef(null);
	const [uploading, setUploading] = useState(false);
	function submitHandler(event) {
		event.preventDefault();
		if (plik) {
			const form = new FormData();
			setUploading(true);
			form.append("action", "uploadFile");
			form.append("sciezka", "/instrukcje/");
			form.append("plik", plikRef.current.files[0]);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					const form = new FormData(event.target);
					form.append("action", "addInstrukcja");
					form.append("id", selected[1]);
					form.append("tryb", insmode);
					form.append("cel", selected[0]);
					form.append("plik", res);
					fetch(apiUrl, {
						mode: mode,
						method: "POST",
						body: form,
					})
						.then((res) => res.text())
						.then((res) => console.log(res))
						.then(() => reload((a) => !a))
						.then(() => setUploading(false));
				});
		} else {
			const form = new FormData(event.target);
			setUploading(true);
			form.append("action", "addInstrukcja");
			form.append("id", selected[1]);
			form.append("tryb", insmode);
			form.append("cel", selected[0]);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.text())
				.then((res) => console.log(res))
				.then(() => reload((a) => !a))
				.then(() => setUploading(false));
		}
	}
	if (selected[0] === "b") {
		return (
			<div>
				<Table bordered striped>
					<thead>
						<tr>
							<th>Instrukcje do biogazowni</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								{instrukcje
									.filter((b) => !b.idczesci)
									.map((a, i) => (
										<Instrukcja
											key={i}
											nazwa={a.nazwa}
											url={a.plik ? uploadsUrl + a.plik : a.link}
											uprawnienia
											id={a.id}
											reload={reload}
										></Instrukcja>
									))}
							</td>
						</tr>
					</tbody>
				</Table>
				<Form onSubmit={submitHandler}>
					<FormGroup>
						<FormLabel>
							<h4>Dodaj instrukcje</h4>
						</FormLabel>
					</FormGroup>
					<FormGroup>
						<FormLabel>Nazwa instrukcji</FormLabel>
						<FormControl name="nazwa" placeholder="nazwa"></FormControl>
					</FormGroup>
					{plik ? (
						<FormGroup>
							<FormLabel>Plik</FormLabel>
							<FormControl
								name="plik"
								placeholder="plik"
								type="file"
								ref={plikRef}
							></FormControl>
							<Button onClick={() => setPlik((a) => !a)}>
								{!plik ? "zmień na plik" : "zmień na link"}
							</Button>
						</FormGroup>
					) : (
						<FormGroup>
							<FormLabel>Link</FormLabel>
							<FormControl name="link" placeholder="link"></FormControl>
							<Button onClick={() => setPlik((a) => !a)}>
								{!plik ? "Zmień na plik" : "Zmień na link"}
							</Button>
						</FormGroup>
					)}
					<FormGroup>
						<Button type="submit" disabled={uploading}>
							{uploading ? "Przesyłanie..." : "Dodaj"}
						</Button>
					</FormGroup>
				</Form>
			</div>
		);
	} else {
		return (
			<div style={{ width: "100%" }}>
				<Table bordered striped>
					<thead>
						<tr>
							<th>Instrukcje do części</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								{Object.values(
									Object.groupBy(
										instrukcje.filter((b) => b.idczesci),
										({ idczesci }) => idczesci
									)
								).map((a, i) => (
									<div key={i}>
										{a.map((b, i) => (
											<Instrukcja
												key={i}
												nazwa={b.nazwa}
												url={b.plik ? uploadsUrl + b.plik : b.link}
												uprawnienia
												id={b.id}
												reload={reload}
											></Instrukcja>
										))}
									</div>
								))}
							</td>
						</tr>
					</tbody>
				</Table>
				<Form onSubmit={submitHandler}>
					<FormGroup>
						<FormLabel>
							<h4>Dodaj instrukcje</h4>
						</FormLabel>
					</FormGroup>
					<FormGroup>
						<FormLabel>Nazwa instrukcji</FormLabel>
						<FormControl name="nazwa" placeholder="nazwa"></FormControl>
					</FormGroup>
					{plik ? (
						<FormGroup>
							<FormLabel>Plik</FormLabel>
							<FormControl
								name="plik"
								placeholder="plik"
								type="file"
								ref={plikRef}
							></FormControl>
							<Button onClick={() => setPlik((a) => !a)}>
								{plik ? "zmień na plik" : "zmień na link"}
							</Button>
						</FormGroup>
					) : (
						<FormGroup>
							<FormLabel>Link</FormLabel>
							<FormControl name="link" placeholder="link"></FormControl>
							<Button onClick={() => setPlik((a) => !a)}>
								{plik ? "zmień na plik" : "zmień na link"}
							</Button>
						</FormGroup>
					)}
					<FormGroup>
						<Button type="submit">Zatwierdź</Button>
					</FormGroup>
				</Form>
			</div>
		);
	}
}
