import { apiUrl, mode, uploadsUrl } from "../../globsls";
import { useState, useEffect, useRef } from "react";
import "./Czat.css";
import {
	Button,
	Col,
	Form,
	FormControl,
	Row,
	Image,
	Modal,
	ModalHeader,
	ModalTitle,
	ModalBody,
} from "react-bootstrap";

export default function Powiadomienia(props) {
	const {
		selBiogaz,
		loggedIn,
		setNofications2,
		showNotifications,
		notifications2,
	} = props;
	const [powiadomienia, setPowiadomienia] = useState([]);
	const trescRef = useRef(null);
	const plikRef = useRef(null);
	const [refresh, reload] = useState(true);
	const [showUpload, setShowUpload] = useState(false);
	useEffect(() => {
		window.setInterval(() => {
			reload((a) => !a);
		}, 2000);
	}, []);
	useEffect(() => {
		if (selBiogaz) {
			const form = new FormData();
			form.append("action", "getPowiadomienia");
			form.append("id", selBiogaz.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					setPowiadomienia(res);
					if (!(showNotifications && notifications2 > 0)) {
						setNofications2(
							res.filter((a) =>
								a.odczytano ? !a.odczytano.includes(`^${loggedIn.id}#`) : true
							).length
						);
					}
				});

			if (showNotifications && notifications2 > 0) {
				const form = new FormData();
				form.append("action", "setOdczytanoPowiadomienia");
				form.append("id", loggedIn.id);
				form.append("czat", selBiogaz.id);
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => res.text())
					.then((res) => console.log(res));
				setNofications2(0);
			}
		}
	}, [selBiogaz, refresh, showNotifications]);
	console.log(powiadomienia);
	function submitHandler(event) {
		event.preventDefault();
		const form = new FormData();
		form.append("action", "addWiadomosc");
		form.append("od", loggedIn.id);
		form.append("do", selBiogaz.id);
		form.append("tresc", trescRef.current.value);
		trescRef.current.value = "";
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.text())
			.then((res) => console.log(res))
			.then((res) => reload((a) => !a));
	}
	const [uploading, setUploading] = useState(false);
	function submitFile() {
		const form = new FormData();
		form.append("action", "uploadFile");
		setUploading(true);
		const date = new Date();
		form.append(
			"sciezka",
			`/biogazowanie/${selBiogaz.nazwa}/4. Korespondencja/`
		);
		//			${date.getDay()}.${date.getMonth() + 1}.${date.getFullYear()}/
		form.append("flagPreciseResponse", "true");
		form.append("plik", plikRef.current.files[0]);
		fetch(apiUrl, {
			mode: mode,
			method: "POST",
			body: form,
		})
			.then((res) => res.json())
			.then((res) => {
				const form = new FormData();
				form.append("action", "addWiadomosc");
				form.append("od", loggedIn.id);
				form.append("do", selBiogaz.id);
				form.append("tresc", `${res.nazwa}[||]${res.plik}`);
				trescRef.current.value = "";
				fetch(apiUrl, {
					mode: mode,
					method: "POST",
					body: form,
				})
					.then((res) => reload((a) => !a))
					.then(() => {
						setShowUpload(false);
						setUploading(false);
					});
			});
	}
	function findShowMore(i) {
		if (
			i === 0 ||
			powiadomienia[i].data_wys !== powiadomienia[i - 1].data_wys
		) {
			return true;
		}
		return i === 0 || powiadomienia[i].od !== powiadomienia[i - 1].od;
	}
	return (
		<>
			<Modal
				show={showUpload}
				onHide={() => setShowUpload(false)}
				onClick={(e) => e.stopPropagation()}
			>
				<ModalHeader>
					<ModalTitle>Wyślij wiadomośc z plikiem</ModalTitle>
				</ModalHeader>
				<ModalBody onClick={(e) => e.stopPropagation()}>
					<FormControl type="file" ref={plikRef}></FormControl>
					<Button
						style={{ marginTop: "0.5rem" }}
						onClick={() => submitFile()}
						disabled={uploading}
					>
						{uploading ? "Przesyłanie pliku..." : "Wyślij"}
					</Button>
				</ModalBody>
			</Modal>
			<div className="Czat">
				<h4>Powiadomienia</h4>
				<div className="powiadomienia">
					{powiadomienia
						.map((a, i) => ({
							dane: a,
							showMore: findShowMore(i),
						}))
						.reverse()
						.map((b, i) => (
							<Wiadomosc
								key={i}
								dane={b.dane}
								showMore={b.showMore}
							></Wiadomosc>
						))}
				</div>
				{/* <center>
					<Form
						onSubmit={submitHandler}
						style={{
							borderTop: "1px solid gray",
							marginTop: "1em",
						}}
					>
						<Row style={{ marginTop: "0.5em" }}>
							<Col style={{ paddingRight: "0.25rem" }}>
								<FormControl type="text" ref={trescRef}></FormControl>
							</Col>
							<Col
								md="auto"
								style={{ paddingRight: "0.5rem", paddingLeft: "0.25rem" }}
							>
								<Button
									onClick={() => setShowUpload(true)}
									style={{
										margin: "0px",
										display: "flex",
										width: "38px",
										height: "38px",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<span className="material-symbols-outlined">attach_file</span>
								</Button>
							</Col>
							<Col md="auto" style={{ paddingLeft: "0px" }}>
								<Button
									type="submit"
									style={{
										margin: "0px",
										display: "flex",
										width: "38px",
										height: "38px",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<span className="material-symbols-outlined">send</span>
								</Button>
							</Col>
						</Row>
					</Form>
				</center> */}
			</div>
		</>
	);
}
function Wiadomosc({ dane, showMore }) {
	return (
		<div className={`wiadomosc ${showMore ? "linia" : ""}`}>
			<Row>
				{true && (
					<Row className="dane" style={{ textAlign: "left" }}>
						<Col>{dane.data}</Col>
					</Row>
				)}

				<Row>
					{/* <Col className="profContainer" md={"0"}>
						{showMore &&
							(dane.obraz !== null ? (
								<img
									src={uploadsUrl + dane.obraz}
									className={"profilowe"}
									alt=""
								></img>
							) : (
								<span
									className="material-symbols-outlined profilowe"
									style={{ fontSize: "33px" }}
								>
									person
								</span>
							))}
					</Col> */}
					<Col style={{ marginLeft: "1rem" }}>
						{showMore && (
							<Row className="wiadomosc nazwaWys">{dane.nazwa_wys}</Row>
						)}
						{dane.tresc.includes("[||]") ? (
							<Row className="wiadomosc">
								<a
									href={`${uploadsUrl}${dane.tresc.split("[||]")[1]}`}
									style={{ margin: "0px", padding: "0px" }}
								>
									{dane.tresc.split("[||]")[0]}
								</a>
							</Row>
						) : (
							<Row className="wiadomosc">{dane.tresc}</Row>
						)}
					</Col>
				</Row>
			</Row>
		</div>
	);
}
