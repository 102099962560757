import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import Login from "./pages/Login";
import Header from "./pages/Header";
import PanelBiogaz from "./pages/panel/PanelBiogaz";

import { apiUrl, mode } from "./globsls";
import PanelSerwis from "./pages/panel/PanelSerwis";
import PanelAdmin from "./pages/panel/PanelAdmin";
import OpcjeKonta from "./pages/OpcjeKonta";
import Footer from "./pages/Footer";

function App() {
	const [refresh, reload] = useState(false);
	const [loggedIn, setLoggedIn] = useState(false);
	const [biogazList, setBiogazList] = useState([]);
	const [selBiogaz, setSelBiogaz] = useState(null);
	const [page, setPage] = useState("panelBiogaz");
	const [key, setKey] = useState("glowny");
	useEffect(() => {
		if (document.cookie || document.cookie === "false") {
			setLoggedIn(
				JSON.parse(document.cookie.split("loggedIn=")[1])[0] || false
			);
		}
	}, []);
	useEffect(() => {
		setPage("panelBiogaz");
	}, [loggedIn]);
	useEffect(() => {
		if (loggedIn !== false && loggedIn !== undefined) {
			const form = new FormData();
			form.append(
				"action",
				loggedIn.typ !== "3" ? "getBiogazownie" : "getBiogazowniePos"
			);
			form.append("posiadacz", loggedIn.id);
			fetch(apiUrl, {
				mode: mode,
				method: "POST",
				body: form,
			})
				.then((res) => res.json())
				.then((res) => {
					if (!selBiogaz) {
						setSelBiogaz(res[1]);
					}

					setBiogazList(res.filter((a) => a.id !== "1"));
				});
		}
	}, [loggedIn, refresh, selBiogaz]);
	return (
		<div className="App">
			<main className="layout">
				<header>
					<Header
						setLoggedIn={setLoggedIn}
						loggedIn={loggedIn}
						biogazList={biogazList}
						setSelBiogaz={setSelBiogaz}
						page={page}
						setPage={setPage}
						selBiogaz={selBiogaz}
						reload={reload}
						setKey={setKey}
					></Header>
				</header>
				<div className="page">
					{!selBiogaz && loggedIn && (
						<div>
							<center>
								<br></br>
								<br></br>
								<h1>
									Konto nie ma przypisanych biogazowni albo jest uszkodzone
								</h1>
								<h3>
									W razie wątpliwości skontaktuj się z adminem na test@test.abc
								</h3>
							</center>
						</div>
					)}
					<Content
						loggedIn={loggedIn}
						setLoggedIn={setLoggedIn}
						selBiogaz={selBiogaz}
						setSelBiogaz={setSelBiogaz}
						reload={reload}
						refresh={refresh}
						page={page}
						setPage={setPage}
						key2={key}
						setKey={setKey}
					></Content>
				</div>
			</main>
		</div>
	);
}
function Content({
	loggedIn,
	setLoggedIn,
	selBiogaz,
	reload,
	refresh,
	page,
	setPage,
	setSelBiogaz,
	setKey,
	key2,
}) {
	if (!loggedIn) {
		return <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn}></Login>;
	}
	if (page === "opcjeKonta") {
		return (
			<OpcjeKonta
				dane={loggedIn}
				reload={reload}
				refresh={refresh}
				setLoggedIn={setLoggedIn}
				loggedIn={loggedIn}
				setPage={setPage}
			></OpcjeKonta>
		);
	} else if (page === "panelBiogaz") {
		return (
			<PanelBiogaz
				loggedIn={loggedIn}
				setLoggedIn={setLoggedIn}
				selBiogaz={selBiogaz}
				reload={reload}
				refresh={refresh}
				setSelBiogaz={setSelBiogaz}
				key2={key2}
				setKey={setKey}
				page={page}
				setPage={setPage}
			></PanelBiogaz>
		);
	} else if (page === "panelSerwis") {
		return (
			<PanelSerwis
				loggedIn={loggedIn}
				setLoggedIn={setLoggedIn}
				selBiogaz={selBiogaz}
				reload={reload}
				refresh={refresh}
			></PanelSerwis>
		);
	} else if (page === "panelAdmin") {
		return (
			<PanelAdmin
				loggedIn={loggedIn}
				setLoggedIn={setLoggedIn}
				selBiogaz={selBiogaz}
				reload={reload}
				refresh={refresh}
			></PanelAdmin>
		);
	}
}
export default App;
