import "./BiologicznyKontakt.css";

import "./Glowny.css";
import { apiUrl, mode, uploadsUrl } from "../../../globsls";

import Kalendarz from "../../components/Kalendarz";
import Zalecenia from "../../components/Glowny/Zalecenia";
import Alerty from "../../components/Glowny/Alerty";
import Diagnoza from "../../components/Glowny/Diagnoza";
import {
	Carousel,
	Col,
	Row,
	Image,
	CarouselCaption,
	Tabs,
	Tab,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import Footer from "../../Footer";

export default function TechnologicznyKontakt(props) {
	const { selBiogaz, loggedIn, reload, refresh } = props;
	return (
		<div className="Glowny" style={{ height: "71.8vh" }}>
			<Row style={{ height: "100%" }}>
				<Col
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
						width: "100%",
						marginLeft: "2rem",
						height: "100%",
					}}
				>
					<div style={{ fontSize: "xx-large", textAlign: "" }}>
						Zespół serwisu technologicznego
					</div>
					<div
						style={{
							marginTop: "2rem",
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<h3>Małgorzata Bednarek</h3>
						<h5>Koordynator Serwisu Technologicznego</h5>
						<div>
							Email:{" "}
							<a
								href="mailto:m.bednarek@agrikomp.pl

"
							>
								m.bednarek@agrikomp.pl
							</a>
						</div>
						<div>Numer telefonu: 530 558 892</div>
					</div>
					<div
						style={{
							marginTop: "2rem",
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<h3>Jakub Janoś</h3>
						<h5>Serwisant - Technolog</h5>
						<div>
							Email:{" "}
							<a
								href="mailto:j.janos@agrikomp.pl



"
							>
								j.janos@agrikomp.pl
							</a>
						</div>
						<div>Numer telefonu: 537 949 299</div>
					</div>
					<div
						style={{
							marginTop: "2rem",
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<h3>Michał Jaśkiewicz</h3>
						<h5>Serwisant</h5>
						<div>
							Email:{" "}
							<a
								href="mailto:m.jaskiewicz@agrikomp.pl




"
							>
								m.jaskiewicz@agrikomp.pl
							</a>
						</div>
						<div>Numer telefonu: 531 438 870</div>
					</div>
					<div
						style={{
							marginTop: "2rem",
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<h3>Michał Szwej</h3>
						<h5>Serwisant</h5>
						<div>
							Email:{" "}
							<a
								href="mailto:m.szwej@agrikomp.pl





"
							>
								m.szwej@agrikomp.pl
							</a>
						</div>
						<div>Numer telefonu: 531 438 870</div>
					</div>
				</Col>

				{/* <img
					src={"/glownyBio.png"}
					alt="obraz"
					style={{
						width: "80%",
						height: "60rem",
						objectFit: "cover",
						justifySelf: "left",
						marginRight: "-1.5rem",
						float: "right",
					}}
				></img> */}
			</Row>
			<div className="footerWrapper">
				{" "}
				<div className="Footer" style={{ position: "static", bottom: "0px" }}>
					<span>© agriKomp Polska Sp. z o.o.</span>
				</div>
				<div className="FooterFiller" style={{ width: "4000px" }}>
					{" "}
					{/* <span style={{ marginRight: "auto" }}>
					<span style={{ marginLeft: "2rem" }}></span>Wersja deweloperska
				</span> */}
				</div>
				<div className="FooterFiller2"></div>
			</div>
		</div>
	);
}
