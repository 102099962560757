import "./PanelBiogaz.css";
import { useEffect, useState } from "react";
import { Tab, Tabs, Dropdown, Nav, NavDropdown } from "react-bootstrap";
import { apiUrl, mode } from "../../globsls";

import Glowny from "./Biogazowni/Glowny";
import Biologiczny from "./Biogazowni/Biologiczny";
import Technologiczny from "./Biogazowni/Technologiczny";
import Kogeneracji from "./Biogazowni/Kogeneracji";
import Dokumenty from "./Biogazowni/Dokumenty";
import Footer from "../Footer";

export default function PanelBiogaz(props) {
	const {
		loggedIn,
		setLoggedIn,
		selBiogaz,
		reload,
		refresh,
		key2,
		setKey,
		setSelBiogaz,
	} = props;
	const [pageB, setPageB] = useState("");
	const [pageT, setPageT] = useState("");
	const [pageK, setPageK] = useState("");
	const [showB, setShowB] = useState(false);
	const [showT, setShowT] = useState(false);
	const [showK, setShowK] = useState(false);
	function pageHandler(e, ek, pag) {
		e.stopPropagation();
		setKey(ek);
		switch (ek) {
			case "biologiczny":
				setPageB(pag);
				break;
			case "technologiczny":
				setPageT(pag);
				break;
			case "kogeneracji":
				setPageK(pag);
				break;
			default:
		}
	}
	if (loggedIn && selBiogaz) {
		return (
			<div className="Panel">
				<Tab.Container activeKey={key2}>
					<Nav variant="tabs" justify>
						<Nav.Item>
							<div className="fillerLeft"></div>
							<Nav.Link onClick={() => setKey("glowny")} eventKey={"glowny"}>
								Strona główna
							</Nav.Link>
							<div className="fillerRight"></div>
						</Nav.Item>
						<Nav.Item
							onMouseEnter={() => setShowB(true)}
							onMouseLeave={() => setShowB(false)}
							onClick={(event) => pageHandler(event, "biologiczny", "")}
						>
							<Nav.Link
								onClick={() => setKey("biologiczny")}
								eventKey={"biologiczny"}
							>
								<NavDropdown title="Serwis biologiczny" show={showB}>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "biologiczny", "wbmf")
										}
									>
										Wyniki badań masy fermentacyjnej
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(event) => pageHandler(event, "biologiczny", "pb")}
									>
										Pozostałe badania
									</Dropdown.Item>
									{/* <Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "biologiczny", "zal")
										}
									>
										Zalecenia
									</Dropdown.Item> */}
									<Dropdown.Item
										onClick={() => window.open("https://kalkulatorbiogazu.pl/")}
									>
										Kalkulator biogazowni{" "}
										<>
											<span
												className="material-symbols-outlined"
												style={{ fontSize: "small" }}
											>
												open_in_new
											</span>
										</>
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "biologiczny", "kon")
										}
									>
										Kontakt
									</Dropdown.Item>
								</NavDropdown>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item
							onMouseEnter={() => setShowT(true)}
							onMouseLeave={() => setShowT(false)}
							onClick={(event) => pageHandler(event, "technologiczny", "")}
						>
							<Nav.Link eventKey={"technologiczny"}>
								<NavDropdown
									title="Serwis technologiczny"
									style={{ border: "none" }}
									show={showT}
								>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "technologiczny", "pzp")
										}
									>
										Plan i zakres przeglądów
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "technologiczny", "hnp")
										}
									>
										Historia napraw i przeglądów
									</Dropdown.Item>
									{/* <Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "technologiczny", "zal")
										}
									>
										Zalecenia
									</Dropdown.Item> */}
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "technologiczny", "zam")
										}
									>
										Zamówienia
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "technologiczny", "ins")
										}
									>
										Instrukcje
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "technologiczny", "kon")
										}
									>
										Kontakt
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "technologiczny", "ppu")
										}
										disabled
									>
										Przegląd pracy urządzeń
									</Dropdown.Item>
								</NavDropdown>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item
							onMouseEnter={() => setShowK(true)}
							onMouseLeave={() => setShowK(false)}
							onClick={(event) => pageHandler(event, "kogeneracji", "")}
						>
							<Nav.Link eventKey={"kogeneracji"}>
								<NavDropdown
									title="Serwis kogeneracji"
									style={{ border: "none" }}
									show={showK}
								>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "kogeneracji", "pzp")
										}
									>
										Plan i zakres przeglądów
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "kogeneracji", "hnp")
										}
									>
										Historia napraw i przeglądów
									</Dropdown.Item>
									{/* <Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "kogeneracji", "zal")
										}
									>
										Zalecenia
									</Dropdown.Item> */}
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "kogeneracji", "zam")
										}
									>
										Zamówienia
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "kogeneracji", "ins")
										}
									>
										Instrukcje
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "kogeneracji", "kon")
										}
									>
										Kontakt
									</Dropdown.Item>
									<Dropdown.Item
										onClick={(event) =>
											pageHandler(event, "kogeneracji", "ppu")
										}
										disabled
									>
										Przegląd pracy silników
									</Dropdown.Item>
								</NavDropdown>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								onClick={() => setKey("dokumenty")}
								eventKey={"dokumenty"}
							>
								Dokumenty
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="raporty" disabled>
								Raporty
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<Tab.Content>
						<Tab.Pane eventKey="glowny">
							<Glowny
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								setSelBiogaz={setSelBiogaz}
							></Glowny>
						</Tab.Pane>
						<Tab.Pane eventKey="biologiczny">
							<Biologiczny
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								page={pageB}
							></Biologiczny>
						</Tab.Pane>
						<Tab.Pane eventKey="technologiczny">
							<Technologiczny
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								page={pageT}
							></Technologiczny>
						</Tab.Pane>
						<Tab.Pane eventKey="kogeneracji">
							<Kogeneracji
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
								page={pageK}
							></Kogeneracji>
						</Tab.Pane>
						<Tab.Pane eventKey="dokumenty">
							<Dokumenty
								selBiogaz={selBiogaz}
								loggedIn={loggedIn}
								reload={reload}
								refresh={refresh}
							></Dokumenty>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</div>
		);
	}
}
